<template>
	<page title="CodeDrills Blog" :apiStatusList="[]">
		Coming Soon ...
	</page>
</template>
<script>
import Page from "../components/Page"
export default {
	components: {
		Page,
	},
}
</script>